@use '../../abstract/colors' as *;

.review {
    border-radius: 16px;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25);
    padding: 30px;
    max-width: 300px;
    margin: 20px;

    &__image {
        max-width: 100px;
        border-radius: 100%;
        margin-top: -100px;
        z-index: 1;
    }

    &__text a {
        color: black;
    }

    &__date {
        display: flex;
        align-items: center;

        svg {
            font-size: 25px;
            color: $blue;
            margin-right: 10px;
        }

        small {
            color: $grey;
        }
    }

}
