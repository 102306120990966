@use './breakpoint' as *;

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
      @media (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }

    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
  }
