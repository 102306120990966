@use '../../abstract/' as *;

.banner {
    position: relative;
    color: white;

    img {
        min-width: 1200px;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.contact-banner {
    color: $offwhite;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}
