@use '../../abstract/' as *;

.footer {
    position: relative;
    display: flex;
    justify-content: center;
    background: $offwhite;
    padding: 100px 15px;
    flex-direction: column;

    > div:not(.lazyload-wrapper) {
        z-index: 1;
    }

    li {
        line-height: 2;
        font-weight: 500;

        a {
            color: black;
        }

        svg {
            color: $blue;
            margin-right: 5px;
        }

        &:hover {
            a {
                letter-spacing: 1.1px;
            }
            svg {
                color: $purple;
            }
        }
    }

    .container {
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    &__links {
        > * {
            width: 80%;
            margin-bottom: 45px;
            box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.35);
            border-radius: 15px;
            padding: 40px;
            position: relative;
            overflow: hidden;
            background: white;

            &:after {
                position: absolute;
                content: '';
                height: 10px;
                width: 10px;
                border-radius: 100%;
                right: 15px;
                bottom: 20px;
                background: linear-gradient(to right, $purple, $blue);
            }

            @include respond-to('small') {
                width: 60%;
                box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);
            }

            @include respond-to('medium') {
                width: 40%;
            }

            @include respond-to('large') {
                width: 25%;
            }
        }
    }

    &__socials {
        svg {
            font-size: 50px;
            color: $blue;

            &:hover {
                color: $purple;
            }
        }
    }

    &__copyright {
        font-weight: 500;
        padding-top: 20px;
    }

    &__image {
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 800px;
    }
}
