@use '../../abstract/' as *;

.contact-form {
    flex-direction: column;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
    padding: 20px;
    border-radius: 15px;

    form {
        display: flex;
        flex-wrap: wrap;
        margin-top: 50px;
    }

    &__field {
        width: 100%;
        display: flex;
        position: relative;
        padding: 10px 10px 30px 10px;

        @include respond-to('small') {
            width: 45%;
            padding: 10px 2.5vw 40px 10px;
        }
    }

    &__error {
        position: absolute;
        bottom: 10px;
        font-size: 13px;
        left: 15px;
        color: $red;

        &--block {
            position: relative;
            bottom: 0;
            left: 0;
            margin-top: 10px;
        }
    }

    &__submit {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-direction: column;

        @include respond-to("small") {
            flex-direction: row;
        }

        button {

            @include respond-to('small') {
                margin-right: 40px;
            }
        }

        input[type='checkbox'] {
            width: 20px;
            height: 20px;
            border: 0;
            margin: 0;
            outline: none;
        }
    }

    &__approve {
        width: 100%;
        position: relative;
        margin-bottom: 20px;

        @include respond-to('small') {
            width: 50%;
            margin: 0 0 0 10px;
        }

        p {
            font-size: 13px;

            @include respond-to('small') {
                font-size: 15px;
            }
        }
    }

    textarea {
        margin: 10px;
        min-height: 200px;
    }
}
