@use '../../abstract/' as *;

.border-gradient {
    border: 10px solid;
    border-image-slice: 1;
    border-width: 10px;
}

.border-gradient-purple {
    border-image-source: linear-gradient(to left, #743ad5, $blue);
}

.bottom-border-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to left, #743ad5, $blue);
    height: 4px;

    @include respond-to('small') {
        height: 8px;
    }
}
