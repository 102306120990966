@use '../../abstract/' as *;

.divider {
    width: 100%;

    > * {
        display: flex;
        align-items: center;

        &:first-child {
            z-index: 1;
        }

        &:last-child {
            background: $offwhite;
            position: relative;
            height: 9vw;
            overflow: hidden;
        }
    }

    &__rect {
        max-width: 200px;
        max-height: 200px;
        width: 12vw;
        height: 12vw;
        position: absolute;
        background: white;
        left: calc(50% - 6vw);
        top: -6vw;
        transform: rotate(45deg);
    }
}
