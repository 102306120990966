@use '../../abstract/' as *;

.homepage {
    &__core-values {
        h2 {
            margin-bottom: 50px;
        }

        .circlestep {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 25px;
            background: white;
            min-height: 200px;
            border-radius: 5px 0 0 5px;
            position: relative;
            overflow: hidden;

            @include respond-to('small') {
                min-height: 150px;
            }

            &__text {
                line-height: 2;
                max-width: 550px;
                font-weight: 500;
                margin: 5px 20px;
                text-align: left;
            }

            &__title {
                padding: 10px 20px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 2px;
                color: black;
                min-width: 300px;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }

    &__steps {
        background: $offwhite;
        padding-bottom: 100px;
        margin-top: -150px;

        @include respond-to('small') {
            padding-bottom: 100px;
        }

        h2 {
            margin-bottom: 0;
        }
    }
}
