@use './typography' as *;
@use './colors' as *;
@use './mixins' as *;

.button {
    border-radius: 2px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    padding: 20px 35px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    color: white;
    font-family: $main-font;
    margin: 10px;
    transition: transform 0.15s ease-in-out;

    @include respond-to('small') {
        margin: 20px;
    }

    &:hover {
        transform: scale(1.05);
    }

    &--white {
        background: $white;
        color: black;
    }

    &--blue {
        background: $blue;
    }

    &--purple {
        background: $purple;
    }
}

.button-submit {
    color: white;
    background-color: #29b7ff;
    padding: 12px 25px;
    border-radius: 5px;
    height: 40px;
    border: 0;
    text-transform: uppercase;
    font-weight: 600;

    &--white {
        background: white;
        color: black;
    };

    &:hover {
        cursor: pointer;
    }
}

.button-sharp {
    margin-right: auto;
    padding: 15px;
    border: 2px solid white;
    margin-top: 25px;
    border-radius: 2px;
    display: inline-block;

    &:hover {
        cursor: pointer;
    }
}

