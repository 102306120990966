@use '../../abstract/' as *;

.header {
    border-bottom: 2px solid $purple;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: white;
    z-index: 20;
    min-height: 70px;

    @include respond-to('small') {
        min-height: 0;
    }

    &__toggle {
        font-size: 30px;
        position: absolute;
        right: 15px;
        top: 15px;
        color: $grey;
    }

    &__svg {
        opacity: 1;
        visibility: visible;

        &--hidden {
            max-width: 0;
            transform: rotate(90deg);
            opacity: 0;
            visibility: hidden;
        }
    }



    &__nav-mobile {
        flex-direction: column;
        align-items: center;
        padding: 20px 0;

        .header__link {
            height: 50px;
        }
    }

    &__cta {
        color: white;
        background: linear-gradient(to right, rgba(116, 58, 213, 1), rgba(41, 183, 255, 1));
        padding: 8px 15px;
        border-radius: 5px;
        display: block;
        margin: 30px 0 0 0;

        &:hover {
            transform: scale(1.05);
        }

        @include respond-to('small') {
            margin: 0 0 0 50px;
        }

        @include respond-to('medium') {
            margin: 0 0 0 100px;
        }
    }

    &__logo-text {
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 2px;
        position: relative;
        margin: 0 auto 0 15px;

        a {
            color: black;
        }

        @include respond-to('medium') {
            margin: 0;
            font-size: 28px;
            display: block;
        }

        &--hidden {
            opacity: 0;
            visibility: hidden;
        }
    }

    &__container {
        width: 80em;
        display: flex;
    }

    &__logo {
        max-width: 200px;
    }

    &__links {
        margin-left: 0 auto;
        display: block;
        flex-direction: row;
        justify-content: center;
        max-height: 400px;
        opacity: 1;
        visibility: visible;

        @include respond-to('small') {
            align-items: center;
            margin-left: auto;
            display: flex;
        }

        &--hidden {
            opacity: 0;
            max-height: 0;
            visibility: hidden;
        }

        > li {
            text-align: center;
        }
    }

    &__link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        padding: 0 30px;
        font-family: $main-font;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        color: $black;

        @include respond-to("small") {
            letter-spacing: 2px;
            font-weight: 500;
            color: $grey;
        }

        &:hover {
            cursor: pointer;
            color: $blue;
        }

        &--active {
            position: absolute;
            left: 0;
            right: 0;
            height: 3px;
            border-radius: 10px;
            background: $blue;
            bottom: 0;

            @include respond-to("small") {
                top: 2px;
            }
        }
    }

    &__transparent {
        background: transparent;
        border-bottom: 0;

        .header__link {
            color: white;
        }

        .header__cta {
            background: transparent;
            border: 2px solid white;
        }

        .header__logo-text {
            a {
                color: white;
            }
        }
    }
}
