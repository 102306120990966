@use '../../abstract/mixins' as *;
@use '../../abstract/colors' as *;

.policies {
    flex-direction: column;
    padding: 100px 0;
    line-height: 2;

    @include respond-to('small') {
        padding-top: 200px;
    }

    h1 {
        display: flex;
        align-items: center;

        &:before {
            @include respond-to('small') {
                content: '';
                min-height: 30px;
                min-width: 30px;
                background: black;
                margin-right: 15px;
                border-radius: 50%;
                background: linear-gradient(to right, $purple, $blue);
            }
        }
    }

    &__subtitle {
        line-height: 1.3;
        font-weight: 500;
        margin-top: 25px;
        text-transform: uppercase;
        font-size: 20px;
    }

    &__list {
        list-style: disc;
        margin: 0 0 30px 15px;
        line-height: 2;
        font-weight: 500;
        font-size: 15px;
        font-style: italic;
    }

    p {
        margin-top: 0;
    }
}
