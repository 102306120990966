@use '../../abstract/mixins' as *;

.contact-page {
    flex-direction: column;
    padding: 100px 0;

    @include respond-to('small') {
        padding-top: 200px;
    }
}
