@use '../../abstract/' as *;

.signup-form {
    max-width: 800px;
    padding: 30px;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    background: $white;

    @include respond-to("small") {
        padding: 40px;
    }

    &__radiogroup {
        display: flex;
        width: 100%;

        @include respond-to("small") {
            width: 54%;
        }

        label {
            width: auto;
            display: flex;
            align-items: center;

            input {
                margin: 0 10px 0 0;
            }
        }
    }

    &__field {
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $black;
        position: relative;
        flex-wrap: wrap;

        @include respond-to("small") {
            flex-wrap: nowrap;
        }

        input {
            width: 100%;

            &[type="radio"] {
                width: auto;
                min-width: 20px;
                min-height: 20px;
                box-shadow: none;
            }

            @include respond-to("small") {
                width: 50%;
            }
        }

        label {
            width: 100%;
            font-weight: 500;
            padding-bottom: 10px;

            @include respond-to("small") {
                width: 40%;
            }
        }
    }

    &__error {
        font-size: 13px;
        color: $red;
        padding-top: 10px;

        @include respond-to("small") {
            position: absolute;
            bottom: 5px;
            right: 0;
            padding: 0;
        }

        &--block {
            left: 0;
        }
    }

    &__submit {
        margin-top: 20px;
    }

    &__approve {
        display: flex;
        color: $black;
        position: relative;
        padding-bottom: 30px;
        flex-direction: column;

        @include respond-to("small") {
            flex-direction: row;
        }

        input {
            width: auto;
            min-height: 20px;
            min-width: 20px;
            margin: 0 15px 0 0;
        }
    }
}
