@use '../../abstract/' as *;

.circlestep {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;

    @include respond-to('small') {
        width: 300px;
        margin: 0;
    }

    &__circle {
        width: 100px;
        height: 130px;
        border-radius: 50%;
        background: $white;
        margin-bottom: 15px;
        // box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 5px 4px 33px 0 rgba(41, 183, 255, 0.1);

        svg {
            font-size: 45px;
            color: $black;
        }

        @include respond-to("small") {
            width: 130px;
            height: 160px;

            svg {
                font-size: 60px;
            }
        }

    }

    &__title {
        font-weight: 800;
    }

    &__text {
        text-align: center;
        font-size: 15px;
        max-width: 350px;
    }
}

.circlesteps {
    flex-wrap: wrap;

    @include respond-to("medium") {
        flex-wrap: nowrap;
    }

    & > * {
        justify-content: center;
        width: 100%;
        display: flex;
    }
}
