@use '../../abstract/mixins' as *;

.signup {
    flex-direction: column;
    padding: 100px 0;

    @include respond-to('small') {
        padding-top: 200px;
    }

    h2 {
        color: white;
    }

    p {
        color: white;
    }

    svg {
        fill: white;
    }

    &__notification {
        h2, p {
            color: black;
        }
    }
}
