@use '../abstract/' as *;

body {
    margin: 0;
    padding: 0;
}

img {
    width: 100%;
    height: auto;
}

* {
    transition: all 0.3s ease-in-out;
    font-family: $main-font;
}

h1, h1 + .Typewriter {
    font-size: 32px;
    font-size: clamp(32px, 6vw, 64px);
    margin: 0;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $main-font;
    word-break: break-word;
    line-height: 1;

    @include respond-to("medium") {
        font-size: 48px;
        font-size: clamp(26px, 6vw, 64px);
    }

    @include respond-to("large") {
        font-size: 64px;
        font-size: clamp(26px, 6vw, 64px);
    }
}

h2 {
    font-size: 24px;
    font-size: clamp(24px, 6vw, 42px);
    font-weight: 700;
    display: flex;
    align-items: center;
    word-break: break-word;

    @include respond-to("medium") {
        font-size: 32px;
        font-size: clamp(24px, 6vw, 42px);
    }

    @include respond-to("large") {
        font-size: 40px;
        font-size: clamp(24px, 6vw, 40px);
    }
}

h4 {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 0;
}

p {
    font-family: $main-font;
    font-size: 15px;
    overflow-wrap: break-word;
    font-weight: 500;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    color: white;
}

input, textarea {
    padding: 15px;
    font-size: 15px;
    border-radius: 5px;
    border: 0;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.15);
    width: 100%;
    -webkit-appearance: none; // IOS FIX

    @include respond-to("small") {
        font-size: 20px;
    }
}

input[type="radio"] {
    -webkit-appearance: radio;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;
}

label {
    font-size: 15px;
}

.container {
    width: 80em;
    display: flex;
    max-width: 100%;
}

.flex {
    justify-content: center;
    display: flex;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.flex-even {
    justify-content: space-evenly;
    display: flex;
    align-items: center;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.justify-flex-start {
    justify-content: flex-start;
}

.justify-content-center {
    justify-content: center;
}

.wrap {
    flex-wrap: wrap;
}

.gap {
    padding: 50px 0;
}

.inline-link {
    color: black;
    text-decoration: underline;
}

.loading {
    opacity: 0;
    visibility: hidden;
}
