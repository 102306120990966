@use '../../abstract/' as *;

.services {
    padding: 100px 0;
    flex-wrap: wrap;
    justify-content: center;

    @include respond-to('small') {
        padding-top: 200px;
    }

    &__example {
        border: 1px solid black;
        padding: 15px;
        border-radius: 5px;
        box-shadow: 3px 4px 0 0 rgba(41, 183, 255, 0.5);
    }

    &__content {
        max-width: 800px;
    }

    &__img {
        width: 30vw;

        @include respond-to("small") {
            width: 300px;
            min-width: 250px;
        }
    }

    p {
        line-height: 2;
        margin-bottom: 30px;
    }

    h1 {
        display: flex;
        align-items: center;

        &:before {
            @include respond-to('small') {
                content: '';
                min-height: 30px;
                min-width: 30px;
                background: black;
                margin-right: 15px;
                border-radius: 50%;
                background: linear-gradient(to right, $purple, $blue);
            }
        }
    }
}
